import ContactForm from "../components/page/ContactForm";

export default function ContactUs() {

    return (
        <>
            <ContactForm />
        </>
    )
}
